/* eslint-disable */

import React from 'react';
import { hydrate } from 'react-dom';
import { ensureReady } from '@jaredpalmer/after';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import routes from './routes';

ensureReady(routes).then((data) => {
  return hydrate(
    <BrowserRouter>
      <App data={data} routes={routes} />
    </BrowserRouter>,
    document.getElementById('root'),
  );
});

if ((module as any).hot) {
  (module as any).hot.accept();
}
