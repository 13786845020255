import './style.scss';
import React, { memo, useEffect, useState, useContext } from 'react';
import { Link, useHistory, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/images/logo/logo-red.png';
import BaseButton from '../Button';
import Door from '../../assets/icons/door2.svg';
import { useLocation } from 'react-router-dom';
import {
  routesWithoutNavAndFooter,
  routesWithNavBorder,
  ROUTES,
} from '../../routes';
import UserContext from '../../UserContext';
import IconUser from '../../assets/icons/components/navbar/icon-user.svg';
import UserService from '../../Services/Users';
import HomeIcon from '../../assets/icons/home.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import { LG_BREAKPOINT } from '../../constants';
import useWindowWidth from '../../hooks/useWindowWidth';

type HeaderProps = {
  isLogin?: boolean;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FunctionComponent<HeaderProps> = ({
  isLogin = false,
  show,
  setShow,
}: HeaderProps) => {
  const location = useLocation();
  const history = useHistory();
  const [border, setBorder] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean | string>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const routesArray = Object.values(ROUTES);

  const user = useContext(UserContext);

  useEffect(() => {
    setLoggedIn(user.ready && user.data !== undefined);
  }, [user]);

  const width = useWindowWidth();
  const [isTablet, setIsTablet] = useState<boolean>(false);
  useEffect(() => {
    setIsTablet(width <= LG_BREAKPOINT);
  }, [width]);

  useEffect(() => {
    setShow(
      !routesWithoutNavAndFooter.find(
        (path) => path == location.pathname || `${path}/` == location.pathname,
      ),
    );

    const isNotFound = routesArray.find(
      (path) => path == location.pathname || `${path}/` == location.pathname,
    );

    const shouldHaveBorder = routesWithNavBorder.find(
      (path) => path == location.pathname || `${path}/` == location.pathname,
    );
    shouldHaveBorder || !isNotFound ? setBorder(true) : setBorder(false);
  }, [location, border]);

  const handleSignout = () => {
    UserService.signOut(user);
    history.push(ROUTES.home);
  };

  return (
    <>
      {show ? (
        <Navbar
          fixed="top"
          className={border ? 'bordered' : ''}
          // collapseOnSelect={true}
          expand="lg"
          style={border ? { borderBottom: '10px solid #e7304f' } : {}}
          //@ts-ignore
          expanded={expanded}
        >
          <Container fluid className="ml-0 mr-0 mt-2">
            <div className={isTablet ? 'mobile-container' : ''}>
              <Link to={ROUTES.home}>
                <Navbar.Brand>
                  <img className="logo" src={Logo} alt="infobeca logo" />
                </Navbar.Brand>
              </Link>
              {isTablet && loggedIn && (
                <Link to={ROUTES.scholarships}>
                  <img
                    className="home-icon"
                    src={HomeIcon}
                    alt="Volver a inicio"
                  />
                </Link>
              )}
            </div>
            <Navbar.Toggle
              onClick={() => setExpanded(expanded ? false : 'expanded')}
            />

            <Navbar.Collapse>
              <div id="left-nav">
                <NavLink
                  className="link"
                  to={ROUTES.about}
                  onClick={() => setExpanded(false)}
                >
                  Sobre nosotros
                </NavLink>
                <NavLink
                  className="link"
                  to={ROUTES.faq}
                  onClick={() => setExpanded(false)}
                >
                  Ayuda
                </NavLink>
                <NavLink
                  className="link"
                  to={ROUTES.contact}
                  onClick={() => setExpanded(false)}
                >
                  Contáctanos
                </NavLink>
              </div>

              <div
                id="right-nav"
                className={user.ready && user.data ? 'right-nav-loggedin' : ''}
              >
                {user.data ? (
                  <>
                    {isTablet && (
                      <hr
                        style={{
                          width: '100%',
                          borderTop: '1 px solid rgba(0,0,0,0.9)',
                        }}
                      />
                    )}
                    <NavLink
                      className="link icon-link account"
                      to={ROUTES.scholarships}
                      onClick={() => setExpanded(false)}
                    >
                      <img src={HomeIcon} className="login-icons" /> Mi cuenta
                    </NavLink>
                    <div className="separator"></div>
                    <NavLink
                      className="link icon-link user"
                      to={ROUTES.profile_user_data}
                      onClick={() => setExpanded(false)}
                    >
                      <img src={IconUser} className="login-icons" /> Editar
                      perfil
                    </NavLink>
                    <div className="separator"></div>

                    <div
                      className="link icon-link logout"
                      onClick={handleSignout}
                    >
                      {isTablet ? (
                        <div>Cerrar sesion</div>
                      ) : (
                        <img src={LogoutIcon} />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Link
                      className="link login-link"
                      to="/iniciar-sesion"
                      onClick={() => setExpanded(false)}
                    >
                      <img className="mr-2 door-icon" src={Door} alt="signin" />
                      Iniciar sesión
                    </Link>
                    <BaseButton
                      className="ml-5 register-button"
                      style="pink"
                      width="154"
                      onClick={() => history.push(ROUTES.register)}
                    >
                      Regístrate
                    </BaseButton>
                  </>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        ''
      )}
    </>
  );
};

export default memo(Header);
