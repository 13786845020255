import { asyncComponent, AsyncRouteProps } from '@jaredpalmer/after';

export const ROUTES = {
  home: '/',
  signin: '/iniciar-sesion',
  register: '/registro',
  faq: '/ayuda',
  about: '/sobre-nosotros',
  search: '/becas/buscar',
  contact: '/contacto',
  validateEmail: '/correo/validar',
  registerForm: '/informacion',
  detail: '/beca/:id',
  profile_edit: '/perfil/editar',
  profile_user_data: '/perfil/informacion',
  profile_membership: '/perfil/membresia',
  profile_change_password: '/perfil/cambiar-contrasena',
  deleteAccount: '/eliminar-cuenta',
  changePassword: '/cambiar-contrasena',
  registerWelcome: '/bienvenido',
  scholarships: '/becas/dashboard',
  scholarshipsRecommended: '/becas/recomendaciones',
  scholarshipsSaved: '/becas/guardadas',
  recoverPassword: '/recuperar-contrasena',
  recoverPasswordSuccess: '/contrasena-cambiada',
  notFound: '/404',
};

const routes: AsyncRouteProps[] = [
  {
    path: ROUTES.home,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Home" */ './views/Home'),
      chunkName: 'Home',
    }),
  },
  {
    path: ROUTES.register,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Register" */ './views/Register'),
      chunkName: 'Register',
    }),
  },
  {
    path: ROUTES.about,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "About" */ './views/About'),
      chunkName: 'About',
    }),
  },
  {
    path: ROUTES.faq,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Faq" */ './views/Faq'),
      chunkName: 'Faq',
    }),
  },
  {
    path: ROUTES.signin,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Sign In" */ './views/SignIn'),
      chunkName: 'Sign In',
    }),
  },
  {
    path: ROUTES.search,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Search" */ './views/Search'),
      chunkName: 'Search',
    }),
  },
  {
    path: ROUTES.contact,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Contact" */ './views/Contact'),
      chunkName: 'Contact',
    }),
  },
  {
    path: ROUTES.validateEmail,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "Validate Email" */ './views/ValidateEmail'
        ),
      chunkName: 'Validate Email',
    }),
  },
  {
    path: ROUTES.registerForm,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "RegisterForm" */ './views/RegisterForm'),
      chunkName: 'Register Form',
    }),
  },
  {
    path: ROUTES.detail,
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Detail" */ './views/Detail'),
      chunkName: 'Detail',
    }),
  },
  {
    path: ROUTES.profile_edit,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "ProfileEdit" */ './views/Profile/Edit'),
      chunkName: 'ProfileEdit',
    }),
  },
  {
    path: ROUTES.profile_user_data,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "ProfileUserData" */ './views/Profile/UserData'
        ),
      chunkName: 'ProfileUserData',
    }),
  },
  {
    path: ROUTES.profile_membership,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "ProfileMembership" */ './views/Profile/Membership'
        ),
      chunkName: 'ProfileMembership',
    }),
  },
  {
    path: ROUTES.profile_change_password,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "ProfileChangePassword" */ './views/Profile/ChangePassword'
        ),
      chunkName: 'ProfileChangePassword',
    }),
  },
  {
    path: ROUTES.deleteAccount,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "Delete account" */ './views/DeleteAccount'
        ),
      chunkName: 'Delete account',
    }),
  },
  {
    path: ROUTES.changePassword,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunName: "Change password" */ './views/ModifiedPassword'
        ),
      chunkName: 'Change password',
    }),
  },
  {
    path: ROUTES.registerWelcome,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Welcome" */ './views/RegisterWelcome'),
      chunkName: 'Welcome',
    }),
  },
  {
    path: ROUTES.scholarships,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: " Scholarships start" */ './views/Scholarships/Start'
        ),
      chunkName: 'Scholarships start',
    }),
  },
  {
    path: ROUTES.recoverPassword,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "RecoverPassword" */ './views/RecoverPassword'
        ),
      chunkName: 'RecoverPassword',
    }),
  },
  {
    path: ROUTES.recoverPasswordSuccess,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "RecoverPasswordSuccess" */ './views/RecoverPassword/RecoverPasswordSuccess'
        ),
      chunkName: 'RecoverPasswordSuccess',
    }),
  },
  {
    path: ROUTES.scholarshipsRecommended,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /*webpackChunkName: "Scholarships recommended" */ './views/Scholarships/Recommended'
        ),
      chunkName: 'Scholarships recommended',
    }),
  },
  {
    path: ROUTES.scholarshipsSaved,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /*webpackChunkName: "SavedScholarships" */ './views/Scholarships/SavedScholarships'
        ),
      chunkName: 'SavedScholarships',
    }),
  },
  {
    path: '**',
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Not Found" */ './views/NotFound'),
      chunkName: 'Not Found',
    }),
  },
];

export const routesWithoutNavAndFooter = [
  ROUTES.signin,
  ROUTES.register,
  ROUTES.validateEmail,
  ROUTES.registerForm,
  ROUTES.recoverPassword,
];

export const routesWithNavBorder = [
  ROUTES.registerWelcome,
  ROUTES.changePassword,
  ROUTES.scholarships,
  ROUTES.scholarshipsRecommended,
  ROUTES.deleteAccount,
  ROUTES.scholarshipsSaved,
  ROUTES.profile_change_password,
  ROUTES.profile_edit,
  ROUTES.profile_membership,
  ROUTES.profile_user_data,
  ROUTES.faq,
  ROUTES.search,
  ROUTES.about
];

export default routes;
