import './style.scss';
import React, { memo, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Logo from '../../assets/images/logo/logo-white.png';
import Facebook from '../../assets/icons/components/footer/facebook.svg';
import Instagram from '../../assets/icons/components/footer/instagram.svg';
import Twitter from '../../assets/icons/components/footer/twitter.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useWindowWidth from '../../hooks/useWindowWidth';
import { SM_BREAKPOINT } from '../../constants';

type FooterProps = {
  show: boolean;
};

const Footer: React.FunctionComponent<FooterProps> = ({ show }) => {
  const width = useWindowWidth();

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(width <= SM_BREAKPOINT);
  }, [width]);

  return (
    <>
      {show ? (
        <footer>
          <Container>
            <Row className="footer-row">
              <Col>
                <Link to={ROUTES.home}>
                  <img
                    className="company-icon"
                    src={Logo}
                    alt="Infobeca logo"
                  />
                </Link>
              </Col>
              <Col>
                <div className="media">
                  <ul>
                    <li className="media-icon">
                      <a
                        href="https://www.instagram.com/info_beca/"
                        target="_blank"
                      >
                        <img src={Instagram} alt="Instagram" />
                      </a>
                    </li>
                    <li className="media-icon">
                      <a
                        href="https://www.facebook.com/info.beca"
                        target="_blank"
                      >
                        <img src={Facebook} alt="Facebook" />
                      </a>
                    </li>
                    <li className="media-icon">
                      <a
                        href="https://twitter.com/info_beca"
                        target="_blank"
                      >
                        <img src={Twitter} alt="Twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className="legal-info" noGutters>
              {!isMobile ? (
                <Col className="legal" md={5}>
                  <span>Todos los derechos reservados 2020, Infobeca.</span>
                </Col>
              ) : (
                <>
                  <span>Todos los derechos reservados 2020, Infobeca.</span>
                </>
              )}

              <Col className="footer-links" md={7}>
                <a
                  href="https://www.globaledupass.com/terminos-y-condiciones"
                  target="_blank"
                >
                  Términos y condiciones
                </a>
                <span> | </span>
                <a
                  href="https://www.globaledupass.com/politica-de-privacidad/"
                  target="_blank"
                >
                  Política de Privacidad
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      ) : (
        ''
      )}
    </>
  );
};

export default memo(Footer);
