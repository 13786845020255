import { Configurations } from './_configuration';

export type Option = {
  text: string;
  dataField: string | undefined;
};

export type RegisterForm = {
  field: Array<string>;
  location: Array<string>;
  season: Array<string>;
  academicLevel: string;
  experience: string;
  reason: Array<string>;
};

export const XS_BREAKPOINT = 0;
export const SM_BREAKPOINT = 576;
export const MD_BREAKPOINT = 768;
export const LG_BREAKPOINT = 992;
export const XL_BREAKPOINT = 1200;

export const VALIDATION_MESSAGES = {
  REQUIRED: 'Este campo es requerido',
  EMAIL: 'Correo electrónico invalido',
  MIN: (min: number) =>
    min ? `Minimo ${min} caracteres requeridos` : 'Se requieren más caracteres',
  URL: 'Invalid URL',
};

export const FILTERS_STR = {
  nivel_estudios: 'Nivel de estudios',
  duracion: 'Duracion',
  idiomas: 'Idiomas',
};

export const PAGINATION_SIZE = 15;

// These are the filters on the navbar
export const FIRST_FILTER = 'area_estudios';
export const SECOND_FILTER = 'pais';

export const OMIT_FILTERS = [
  FIRST_FILTER,
  SECOND_FILTER,
  'duracion',
  'area_estudio',
];

export type SearchNoRegister = {
  firstSearch: Date | undefined;
  scholarships: Array<string>;
  searches: number;
};

export const SEARCH_LIMIT = 5;
export const DAYS_LIMIT = 7;

export const FACEBOOK_LOGIN_URL = Configurations.backendUrl + '/login/facebook';
export const GOOGLE_LOGIN_URL = Configurations.backendUrl + '/login/google';
