import './style.scss';
import React, { memo } from 'react';
import Button from 'react-bootstrap/Button';
import cls from 'classnames';

const DEFAULT_TYPE = 'button';
const DEFAULT_HEIGHT = '40px';
const DEFAULT_WIDTH = 'fit-content';

type BaseButtonProps = {
  className?: string;
  height?: string | number;
  width?: string | number;
  type?: 'button' | 'reset' | 'submit';
  style?: 'pink' | 'gray' | 'transparent' | 'white' | 'red' | 'blue';
  size?: 'sm' | 'lg';
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline-primary'
    | 'outline-secondary'
    | 'light';
  children: any;
  disabled?: boolean;
  block?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
};

const BaseButton: React.FunctionComponent<BaseButtonProps> = ({
  className,
  type = DEFAULT_TYPE,
  style,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  size = 'sm',
  variant = 'primary',
  children,
  disabled = false,
  block = false,
  onClick,
}: BaseButtonProps) => (
  <Button
    className={cls('btn', `${style ? 'btn-' + style : ''}`, className)}
    style={{ height, maxWidth: block ? 'unset' : width }}
    size={size}
    type={type}
    variant={variant}
    disabled={disabled}
    block={block}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default memo(BaseButton);
