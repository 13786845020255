import { Configurations } from '../../_configuration';

export default class BaseService {
  private _serviceURL: string;
  protected _baseURL: string;

  /**
   * @param {string} baseServiceURL Base service URL (ex. "api/user")
   */
  constructor(baseServiceURL: string) {
    this._serviceURL = `${Configurations.backendUrl}/${baseServiceURL}`;
    this._baseURL = Configurations.backendUrl!;
  }

  /**
   * @param {string} [apiPath] Relative path to the specific api type.
   *
   * @return {string}
   * @protected
   */
  _getURL(apiPath: string = ''): string {
    return `${this._serviceURL}/${apiPath}`;
  }
}
