import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { After, AsyncRouteProps } from '@jaredpalmer/after';
import './App.scss';
import Header from './components/Navbar/index';
import Footer from './components/Footer/index';
import UserContext, { UserContextType } from './UserContext';
import UserService from './Services/Users/index';
import has from 'lodash/has';
import {
  GTMinitialize,
  FBpixelInitialize,
  GTMpageTrack,
  FBpixelPageTrack,
} from './Utils/tracking';

type AppProps = {
  data: any;
  routes: AsyncRouteProps[];
};

declare global {
  interface Window {
    dataLayer: {
      push(event: any): void;
    };
  }
}

GTMinitialize(process.env.RAZZLE_GOOGLE_TAG_ID!);
FBpixelInitialize(process.env.RAZZLE_FB_PIXEL_ID!);

const App: React.FunctionComponent<AppProps> = ({ data, routes }: AppProps) => {
  const history = useHistory();
  const [show, setShow] = useState(true);

  const updateUser = async () => {
    const localUser = UserService.getUserFromLocalStorage();
    if (localUser) {
      const response = await UserService.getUser(localUser.id!);
      if (has(response.data, 'id_usuario')) {
        setUser({ data: response.data, update: updateUser, ready: true });
      } else {
        setUser({ data: undefined, update: updateUser, ready: true });
      }
    } else {
      setUser({ data: undefined, update: updateUser, ready: true });
    }
  };

  const [user, setUser] = useState<UserContextType>({
    data: undefined,
    update: updateUser,
    ready: false,
  });

  useLayoutEffect(() => {
    updateUser();
  }, []);

  useEffect(() => {
    GTMpageTrack();
    FBpixelPageTrack();
  }, []);

  useEffect(() => {
    history.listen(() => {
      GTMpageTrack();
      FBpixelPageTrack();
    });
  }, [history]);

  return (
    <>
      <UserContext.Provider value={user}>
        <Header show={show} setShow={setShow} />
        <After data={data} routes={routes} transitionBehavior="blocking" />
      </UserContext.Provider>
      <Footer show={show} />
    </>
  );
};

export default App;
