import axios from 'axios';
import BaseService from '../Base/index';
import {
  UserTypePromise,
  UserLogin,
  User,
  RegisterFormFields,
  RegisterFormExportFields,
  RegisterProps,
  ChangePassword,
} from '../../models';
import { UserContextType } from '../../UserContext';
import { decrypt, encrypt } from '../../Utils';

class UserService extends BaseService {
  USER_LS_KEY = 'infobecas-user';

  constructor() {
    super('users');
  }

  async getAll(): Promise<UserTypePromise> {
    try {
      const { data } = await axios.get(this._getURL(''));
      return { data: data };
    } catch ({ response }) {
      return { error: response.data };
    }
  }

  async getUser(id: string): Promise<UserTypePromise> {
    try {
      const { data } = await axios.get(this._getURL(`${id}`));
      return { data: data[0] };
    } catch ({ response }) {
      return { error: response };
    }
  }

  async update(userData: User): Promise<UserTypePromise> {
    try {
      const { data } = await axios.post(
        this._getURL(`${userData.id_usuario}`),
        userData,
      );
      return { data: data };
    } catch {
      return { error: 'There was an error with the server' };
    }
  }

  async register(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
  ): Promise<RegisterProps> {
    const body = {
      correo_elect: email,
      primer_nombre: firstName,
      primer_apellido: lastName,
      password,
      terminos_ref: true,
      val_correo: false,
      rol: 'usuario',
    };

    try {
      const {
        data: { id },
      } = await axios.post(`${this._baseURL}/register`, body);
      return { id };
    } catch {
      return { error: true };
    }
  }

  async login(email: string, password: string): Promise<UserLogin> {
    const body = {
      correo_elect: email,
      password: password,
    };

    try {
      const {
        data: { correo_elect: email, id_usuario: id },
      } = await axios.post(`${this._baseURL}/login`, body);

      return { email: email, id: id };
    } catch ({
      response: {
        data: { error },
      },
    }) {
      return { error };
    }
  }

  async changePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<boolean> {
    const body = {
      correo_elect: email,
      password_viejo: oldPassword,
      password_nuevo: newPassword,
    };

    try {
      await axios.post(`${this._baseURL}/cambio_pass`, body);

      return true;
    } catch {
      return false;
    }
  }

  async getRegisterFormFields(): Promise<RegisterFormFields> {
    const empty_fields: RegisterFormFields = {
      nivel_estudios: [],
      area_estudio: [],
      temporada: [],
      duracion: [],
      nacionalidad: [],
      pais_interes: [],
      idiomas: [],
    };

    try {
      const { data: form } = await axios.get(
        `${this._baseURL}/get_inputs_registro/`,
      );

      return form[0] as RegisterFormFields;
    } catch {
      return empty_fields;
    }
  }

  async updateUserRegisterInfo(
    userId: string,
    registerInfo: RegisterFormExportFields,
  ): Promise<boolean> {
    const body = {
      id_usuario: userId,
      nacionalidad: registerInfo.nacionalidad ?? '',
      nivel_educ: registerInfo.nivel_educ ?? '',
      idiomas: registerInfo.idiomas ?? [],
      interes_estudios: registerInfo.interes_estudios ?? [],
      pais_estud: registerInfo.pais_estud ?? [],
      temp_conv: registerInfo.temp_conv ?? [],
      experiencia: registerInfo.experiencia ?? 0,
      razon_estudios: registerInfo.razon_estudios ?? [],
    };

    try {
      await axios.post(this._getURL(`registro/${userId}`), body);

      return true;
    } catch {
      return false;
    }
  }

  saveToLocalStorage(user: UserLogin) {
    localStorage.setItem(this.USER_LS_KEY, encrypt(JSON.stringify(user)));
  }

  removeUserFromLocalStorage() {
    localStorage.removeItem(this.USER_LS_KEY);
  }

  getUserFromLocalStorage(): UserLogin | null {
    try {
      return localStorage.getItem(this.USER_LS_KEY) !== null
        ? (JSON.parse(
            decrypt(localStorage.getItem(this.USER_LS_KEY)!),
          ) as UserLogin)
        : null;
    } catch {
      return null;
    }
  }

  signOut(user: UserContextType) {
    this.removeUserFromLocalStorage();
    user.update();
  }

  async hasCompletedRegisterForm(): Promise<boolean> {
    // User MUST be logged in
    try {
      const { id } = this.getUserFromLocalStorage() as UserLogin;
      const { data: user } = await this.getUser(id!);

      const {
        interes_estudios,
        pais_estud,
        temp_conv,
        experiencia,
        razon_estudios,
      } = user!;

      return (
        interes_estudios !== null &&
        interes_estudios.length > 0 &&
        temp_conv !== null &&
        temp_conv.length > 0 &&
        experiencia !== null &&
        pais_estud !== null &&
        pais_estud.length > 0 &&
        razon_estudios !== null &&
        razon_estudios.length > 0
      );
    } catch {
      return false;
    }
  }

  async sendSignUpVerificationEmail(email: string): Promise<boolean> {
    try {
      await axios.post(`${this._baseURL}/val_email/send`, {
        email,
      });
      return true;
    } catch (err) {
      return false;
    }
  }

  async verifySignUpEmail(email: string, code: string): Promise<boolean> {
    const body = {
      email,
      codigo_validacion: code,
    };

    try {
      await axios.post(`${this._baseURL}/val_email/verify`, body);
      return true;
    } catch (err) {
      return false;
    }
  }

  async sendForgotPasswordCode(email: string): Promise<ChangePassword> {
    const body = {
      email: email,
    };

    try {
      const { data } = await axios.post(
        `${this._baseURL}/olvido_password/send`,
        body,
      );
      return data;
    } catch ({ response }) {
      return response.data;
    }
  }

  async verifyForgotPasswordCode(
    email: string,
    code: string,
  ): Promise<ChangePassword> {
    const body = {
      email: email,
      codigo_validacion: code,
    };

    try {
      const { data } = await axios.post(
        `${this._baseURL}/olvido_password/verify`,
        body,
      );
      return data;
    } catch ({ response }) {
      return response.data;
    }
  }

  async resetPassword(
    email: string,
    newPasword: string,
  ): Promise<ChangePassword> {
    const body = {
      email: email,
      password_nuevo: newPasword,
    };

    try {
      const { data } = await axios.post(
        `${this._baseURL}/olvido_password/cambio_pass`,
        body,
      );

      return data;
    } catch ({ response }) {
      return response.data;
    }
  }

  async deleteAccount(userCtx: UserContextType) {
    try {
      const user = this.getUserFromLocalStorage();
      const { data } = await axios.delete(this._getURL(user?.id));
      userCtx.update();
      return data;
    } catch ({ response }) {
      return response.data;
    }
  }

  async setSocialUserFirstTimeToFalse(id: string): Promise<boolean> {
    try {
      const body = {
        id,
      };

      await axios.post(this._getURL('primera_vez/'), body);
      return true;
    } catch {
      return false;
    }
  }

  async sendEmailToCompany(
    name: string,
    email: string,
    message: string,
  ): Promise<boolean> {
    try {
      const body = {
        message,
        correo: email,
        nombre: name,
      };

      await axios.post(`${this._baseURL}/correo_contacto/send`, body);
      return true;
    } catch {
      return false;
    }
  }
}

export default new UserService();
