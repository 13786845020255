import { useEffect, useState } from 'react';

export default function useWindowWidth(): number {
  const isClient = typeof document === 'object';

  function getSize(): number {
    return isClient ? document.body.clientWidth : 0;
  }

  const [width, setWidth] = useState(getSize());

  useEffect((): any => {
    if (!isClient) {
      return false;
    }

    function handleResize(): void {
      setWidth(getSize());
    }

    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return width;
}
