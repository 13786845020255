import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import { isProd } from '.';

export function GTMinitialize(id: string): void {
  // Initialize Google Tag Manager
  TagManager.initialize({
    gtmId: id,
  });
}

export function FBpixelInitialize(id: string): void {
    // Initialize FB Pixel
    ReactPixel.init(id);
}

export function GTMpageTrack(): void {
  if (isProd() && window) {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname + window.location.search,
        title: window.location.pathname,
      },
    });
  }
}

export function FBpixelPageTrack(): void {
  if (isProd()) ReactPixel.pageView();
}
