import { Configurations } from '../_configuration';
import CryptoJS from 'crypto-js';

import React from 'react';
import Button from 'react-bootstrap/Button';
import Arrow from '../assets/icons/arrow.svg';
import capitalize from 'lodash/capitalize';
import { format } from 'date-fns';
import { CustomDate } from '../models';
import omit from 'lodash/omit';
import { isArray } from 'lodash';

// TODO - this need to be moved to a Component. This should not be in Utils.

export const LabelToggle = (className = '', variant = 'a', title?: string) =>
  // @ts-ignore
  React.forwardRef<any>(({ children, onClick }, ref) => (
    <>
      {variant === 'a' && (
        <a
          className={`label-dropdown ${className}`}
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <span>{children}</span>
          <img className="icon" src={Arrow} alt="flecha" />
        </a>
      )}
      {variant === 'button' && (
        <Button
          className={`label-dropdown btn-dropdown ${className}`}
          ref={ref}
          // @ts-ignore
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <span>{children}</span>
          <img className="icon" src={Arrow} alt="flecha" />
        </Button>
      )}
      {variant === 'searchbar' && (
        <>
          <a
            style={{ height: '100%', width: '100%', display: 'block' }}
            className={`label-dropdown ${className}`}
            ref={ref}
            onClick={(e) => {
              e.preventDefault();
              onClick(e);
            }}
          >
            <div className="selector">{title}</div>
            <span>{children}</span>
            <img className="icon" src={Arrow} alt="flecha" />
          </a>
        </>
      )}
    </>
  ));

export const filterFilters = (filters: any): any => {
  Object.keys(filters).map((key) => {
    filters[key] = filters[key].map((option: string) => {
      return { text: capitalize(option), dataField: option };
    });
  });
  return filters;
};
//@ts-ignore
export const getBgUrl = (el: any) => {
  let bg = '';
  if (el.currentStyle) {
    bg = el.currentStyle.backgroundImage;
  } else if (document.defaultView && document.defaultView.getComputedStyle) {
    bg = document.defaultView.getComputedStyle(el, '').backgroundImage;
  } else {
    bg = el.style.backgroundImage;
  }
  return bg.replace(/url\(['"]?(.*?)['"]?\)/i, '$1');
};

export function getDateFromString(
  data: string | Date = new Date(),
): CustomDate {
  const myDate = new Date(data == undefined ? new Date() : data);
  const year = format(myDate, 'YYY');
  const month = format(myDate, 'MMMMMMM');
  const day = format(myDate, 'dd');
  const dayOfTheWeek = format(myDate, 'iiii');
  const hour = format(myDate, 'HH');
  const minutes = format(myDate, 'mm');
  const seconds = format(myDate, 'ss');
  const amOrPm = format(myDate, 'aa');
  const monthNumber = format(myDate, 'M');

  const yourDate = {
    day: day,
    month: month,
    year: year,
    dayOfTheWeek: dayOfTheWeek,
    hour: hour,
    minutes: minutes,
    seconds: seconds,
    amOrPm: amOrPm,
    monthNumber: monthNumber,
  };

  return yourDate;
}

export const encrypt = (str: string): string => {
  return CryptoJS.AES.encrypt(str, Configurations.encryptionSecret!).toString();
};

export const decrypt = (str: string): string => {
  var bytes = CryptoJS.AES.decrypt(str, Configurations.encryptionSecret!);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export function isProd() {
  return process.env.NODE_ENV === 'production';
}

export const filterUndefinedFields = (obj: any): any => {
  const undef = Object.keys(obj).filter((key) => {
    if (isArray(obj[key]))
      return obj[key].length === 0 || obj[key][0] === undefined;
    else return obj[key] === undefined;
  });
  return omit(obj, undef);
};
